import React from 'react'
import Container from '../component/Container'
import Layout from '../component/Layout'
import Header from '../component/Header'
import Icone from '../images/svg/bot.svg'
import Seo from '../component/Seo'

export default function Credit() {
  return (
    <div className="h-screen">
      <Seo 
        title="Crédits blog remote monkey"
        description="Bienvenue sur Remote Monkey : Responsable de publication | Arnaud Desportes." 
      />
      <Layout>
        <Header description="Remote Monkey, web dévelopement S.A.S.U" icone={Icone}>
          <span className="text-primary">Crédits</span> Arnaud desportes
        </Header>
        <Container maxWidth="max-w-2xl" className="text-center my-20">
          <h3 className="text-4xl font-extrabold tracking-tights">Bienvenue sur <span className="text-primary">Remote Monkey</span></h3>
          <h4 className="text-md font-bold mb-5">Responsable de publication | Arnaud Desportes.</h4>
          <p className="mt-2 mb-10">Bienvenue sur mon blog l’endroit où j’aire secrètement, pendant mes heures perdu. Je partage ici des tips, des tutos, des podcasts, des vidéos & et tout un tas de ressources pour égayer le parcours des codeurs qui me lisent.</p>
          <span className="text-xs">
            Fièrement déployé sur 
            <a href="https://firebase.google.com/" target="_blank" rel="noreferrer" 
              className="mx-1 text-primary underline">
                Firebase
            </a>
          </span>
        </Container>
      </Layout>
    </div>
  )
}
