import React from 'react'

export default function Container({children, className, maxWidth}) {
  
  if (!maxWidth){ maxWidth = 'max-w-6xl' }

  return (
    <div className={`${maxWidth} w-full mx-auto ${className} px-2 md:px-5 xl:px-0`}>
      {children}
    </div>
  )
}
