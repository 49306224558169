/**
 * Template Liste des Articles d'une categories
 */
import React from "react"
import { graphql, Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import 'moment/locale/fr'
import moment from 'moment'
import Layout from '../component/Layout'
import Container from "../component/Container"
import Header from '../component/Header'
import Icone from '../images/svg/formations.svg'
import Seo from "../component/Seo"
// import {GetDynamicImg} from '../utils/GetDynamicImg'

export const query = graphql`
  query PostsCategory($slug: String!) {
    allWpPost(filter: {categories: {nodes: {elemMatch: {slug: {eq: $slug}}}}}) {
      edges {
        node {
          title
          excerpt
          slug
          date
          modified
          id
          status
          categories {
            nodes {
              name
              description
            }
          }
          featuredImage {
            node{
              altText
              mediaItemUrl
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    width: 572
                    quality: 90
                  )
                }
              }
            }
          }
        }
      }
    } 
  }
`

const BlogCategory = ({ data }) => {

  const ArraylistePosts = data.allWpPost.edges

  let category
  if (ArraylistePosts.length > 0){
    category = ArraylistePosts[0].node.categories.nodes[0]
  }

  return (
    <Layout>
      <Seo 
        title={`Thématique : ${category ? category.name : 'Dévelopement web front end react'}`}
        description={`${category ? category.description : 'Liste des articles du blog Remote Monkey, des articles sur la thématique du développement web, du freelancing & du web design pour égayer ton parcours de codeur...'}`} 
      />
      {ArraylistePosts.length > 0 ? 
        <Header type="headerPostsList" description={category.description} icone={Icone}>
          {category.name}
        </Header>
      : null
      }
      <Container>
        {ArraylistePosts.length <= 0 ?
          <div className="flex flex-col items-center justify-center my-20 max-w-sm mx-auto text-center">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-16 w-16 bg-primary p-4 rounded-full text-white mb-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 3v4M3 5h4M6 17v4m-2-2h4m5-16l2.286 6.857L21 12l-5.714 2.143L13 21l-2.286-6.857L5 12l5.714-2.143L13 3z" />
            </svg>
            <span className="text-xl">Cette catégorie ne comporte aucun article pour le moment.</span>
          </div>
        :
          <div className="grid md:grid-cols-3 gap-2 mt-10">
            {ArraylistePosts.map((post, id) => (
              post.node.status === "publish" ?
                <Link to={`/articles/${post.node.slug}/`} key={id} className="border border-gray-800 hover:border hover:border-gray-700 animate group">
                  <div className="h-full flex flex-col justify-between bg-gray-900">
                    <div className="relative w-full h-80 md:h-60 overflow-hidden bg-gray-800 text-xs">
                      
                      {post.node.featuredImage === null || post.node.featuredImage.node === null ? null : 
                        <GatsbyImage image={getImage(post.node.featuredImage.node.localFile)} alt={""} imgClassName="relative z-20 w-full object-cover object-center flex items-center justify-center text-center" className="w-full h-full p-4" />
                      }
        
                      <div className="absolute z-30 bottom-0 left-0 bg-black bg-opacity-70 w-full py-2 px-4 flex items-center justify-between">
                        <span className="flex items-center text-xs">
                          <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-2 text-primary" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                          </svg>
                          <time className="text-xs" dateTime={moment(post.node.modified).format('LL')}>{moment().startOf(post.node.modified).fromNow()}</time>
                        </span>
                      </div>
                    </div>
                    <h2 className="mt-4 mb-3 text-xl group-hover:text-indigo-300 animate px-4">{post.node.title}</h2>
                    <div className="text-sm mb-3 group-hover:text-indigo-300 animate px-4" dangerouslySetInnerHTML={{ __html: post.node.excerpt }} /> 
                    <div className="px-4 pb-4">
                      <button className="inline-block rounded-full border border-gray-700 group-hover:border-indigo-300 group-hover:text-indigo-400 text-xs mt-2 py-2 px-6 animate">
                        <span>Lire l'article</span>
                      </button>
                    </div>
                  </div>
                </Link> 
              : null
            ))}
          </div>
        }
      </Container>
    </Layout>
  )
  
}

export default BlogCategory