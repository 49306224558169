import React from 'react'
import Photo from '../images/svg/formation-videos.svg'

export default function CallToActionFormation() {
  return (
    <a href="https://formations.arnaud-desportes.fr/" className="group flex flex-col md:flex-row items-center justify-between bg-gray-900 hover:bg-primary bg-opacity-20 border border-gray-900 mt-10 p-5 animate">
      <div className="flex items-center">
        <img src={Photo} alt="Arnaud Desportes - Développeur Front-End React Freelance" className="w-32 h-32 mr-5" />
        <div>
          <h3 className="text-2xl font-extrabold mb-0">Formations vidéos en ligne</h3>
          <h4 className="text-primary group-hover:text-gray-100 animate">Forme-toi au métier de développeur Front-end.</h4>
        </div>
      </div>
      <div className="mt-5 md:mt-0">
        <button className="bg-primary hover:bg-secondary group-hover:bg-white group-hover:text-primary text-xs uppercase py-3 px-6 animate">Formations complètes</button>
      </div>
    </a>
  )
}
