import React from 'react'
import Container from './Container'

export default function HeaderHomePage({description, icone, children, type}) {

  if (type === "headerPostsList"){
    return (
      <div className="w-full py-10 border-b border-gray-700 bg-gray-800 flex items-center">
        <Container className="flex items-center justify-around">
          <div className="text-center">
            <h1 className="text-xl sm:text-2xl font-extrabold tracking-tights uppercase" dangerouslySetInnerHTML={{ __html: children }} />
            <h2 className="mt-2" dangerouslySetInnerHTML={{ __html: description }} />
          </div>
        </Container>
      </div>
    )
  }
  else {
    return (
      <div className="w-full py-10 border-b border-gray-700 bg-gray-800 flex items-center">
        <Container className="flex flex-col-reverse md:flex-row items-center justify-around text-center md:text-left">
          <div>
            <h1 className="text-xl font-extrabold tracking-tights uppercase sm:text-4xl">{children}</h1>
            <h2 className="text-sm font-extrabold tracking-tights sm:text-lg">{description}</h2>
          </div>
          <img src={icone} alt="" className="w-28 h-28" />
        </Container>
      </div>
    )
  }

}
