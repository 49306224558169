import { Link } from 'gatsby'
import React from 'react'
import Container from '../component/Container'
import Layout from '../component/Layout'
import Header from '../component/Header'
import Icone404 from '../images/svg/404-error.svg'
import Seo from '../component/Seo'

export default function Error404() {
  return (
    <div className="h-screen">
      <Seo 
        title="Erreur 404"
        description="Ce contenu n’existe plus ou a été déplacé récemment..." 
      />
      <Layout>
        <Header description="Ce contenu n’existe plus ou a été déplacé récemment..." icone={Icone404}>
          Erreur <span className="text-primary">404</span>
        </Header>
        <Container className="text-center my-20">
          <h3 className="text-4xl font-extrabold tracking-tights">Bienvenue sur <span className="text-primary">Remote Monkey</span></h3>
          <h4 className="text-xl mt-2">Un blog utile a la communauté des <b>développeurs Front-End React</b>, ici on parle de</h4>
          <h5 className="text-md">Développement web, de web design et de freelancing...</h5>
          <Link to="/" className="inline-block rounded-full border border-gray-700 hover:border-indigo-500 hover:text-indigo-500 text-sm mt-5 py-2 px-6 animate">Page d'accueil</Link>
        </Container>
      </Layout>
    </div>
  )
}

