/**
 * Rôle : Liste des articles du blog dans l'ordre du plus récents.
 */
 import React from 'react'
 import { useStaticQuery, graphql, Link } from "gatsby"
 import { GatsbyImage, getImage } from "gatsby-plugin-image"
//  import moment from 'moment'
//  import 'moment/locale/fr'
 
 export default function ListAllPostsBlog({className}) {
 
  const data = useStaticQuery(graphql`
   query listeAllPostsBlog {
    allWpPost(
      sort: {order: DESC, fields: [modified]}
    ) {
      edges {
        node {
          title
          excerpt
          slug
          date
          modified
          id
          categories {
            nodes {
              slug
              name
              id
            }
          }
          featuredImage {
            node{
              altText
              mediaItemUrl
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    width: 572
                    quality: 90
                  )
                }
              }
            }
          }
        }
      }
    }
  }`)
 
  const ArraylistePosts = data.allWpPost.edges;
  
  return (  
    <div className={`${className}`}>
      {ArraylistePosts.map((post, id) => (
        <Link to={`/articles/${post.node.slug}/`} key={id} className="bg-green-500 border border-gray-800 hover:border hover:border-gray-700 animate group">
          <div className="h-full flex flex-col justify-between bg-gray-900">
            <div>
              <div className="relative w-full h-80 md:h-60 overflow-hidden bg-gray-800 text-xs">
                
                {post.node.featuredImage === null || post.node.featuredImage.node === null ? null :
                  <GatsbyImage image={getImage(post.node.featuredImage.node.localFile)} alt={post.node.featuredImage.node.altText} imgClassName="relative z-20 w-full object-cover object-center flex items-center justify-center text-center" className="w-full h-full p-4" />
                }

                <div className="absolute z-30 bottom-0 left-0 bg-black bg-opacity-70 w-full py-2 px-4 flex items-center justify-between">
                  <span className="mr-4 flex items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-2 text-primary" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10" />
                    </svg>
                    <span className="text-xs">{post.node.categories.nodes[0].name}</span>
                  </span>
                </div>
              </div>
              <h2 className="mt-4 mb-3 text-xl group-hover:text-indigo-500 animate px-4">{post.node.title}</h2>
              <div className="text-sm mb-3 animate px-4" dangerouslySetInnerHTML={{ __html: post.node.excerpt }} /> 
            </div>
            <div className="px-4 pb-4">
              <button className="inline-block rounded-full border border-gray-700 group-hover:bg-primary group-hover:border-primary group-hover:text-white text-xs mt-2 py-2 px-6 animate">
                <span>Lire l'article</span>
              </button>
            </div>
          </div>
        </Link>
      ))}
    </div>
  )
}
 
 




