/**
 * Général Body 
 */
import React from 'react'
import Footer from './Footer'
import Menu from './Menu'


export default function Layout({children}) {
  return (
    <div>
      <Menu />
      {children}
      <Footer />
    </div>
  )
}
