/*
* Fonction permettant de générer une image dynamiquement en fonction de son nom, si l'image n'éxiste pas proposition d'une image par défault.
*/
export const GetDynamicImg = (id, type) => {
  let imgFeatured
  try{
    imgFeatured = require(`../images/category/${id}.jpg`).default
  }
  catch(err){
    imgFeatured = require(`../images/imgNotFound.jpg`).default
  }
  return imgFeatured
}