import React, {useEffect} from "react"
import { graphql, Link } from "gatsby"
import parse from 'html-react-parser'
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Layout from '../component/Layout'
import Container from '../component/Container'
import moment from 'moment'
import 'moment/locale/fr'
import Prism from "prismjs"
import '../styles/prism-vs-code.css'
import Seo from "../component/Seo"
import CallToActionFormation from "../component/CallToActionFormation"

export const query = graphql`
  query($slug: String!) {
    allWpPost(filter: { slug: { eq: $slug } }) {
      edges { 
        node {
          title
          content
          slug
          id
          status
          excerpt
          modified(locale: "fr", fromNow: false)
          categories {
            nodes {
              name
              slug
            }
          }
          featuredImage {
            node{
              altText
              caption
              mediaItemUrl
              localFile {
                childImageSharp{
                  gatsbyImageData(
                    width: 768
                    quality: 90
                  )
                }
              }
            }
          }
        }
      }
    }
  }`
 
  const BlogPost = ({ data }) => {
    
    useEffect(() => {
      // call the highlightAll() function to style our code blocks
      Prism.highlightAll()
    })

    const post = data.allWpPost.edges[0].node
    const image = getImage(post.featuredImage.node.localFile)
    
    const SeoDescription = <p dangerouslySetInnerHTML={{ __html: post.excerpt }} />                    
    const SeoDescription1 = SeoDescription.props.dangerouslySetInnerHTML.__html.replace("<p>", "")
    const SeoDescription2 = SeoDescription1.replace("</p>", "")
    const SeoDescription3 = SeoDescription2.replace("&hellip;", "...")
    const SeoDescription4 = SeoDescription3.replace("&rsquo;", "'")
    
    if (post.status === "publish") {
      return (
        <Layout>
          <Seo 
            title={post.title}
            description={SeoDescription4} 
          />
          <Container maxWidth="max-w-3xl">

            {/* HEADER */}
            <div className="relative my-5">
              <h1 dangerouslySetInnerHTML={{ __html: post.title }} className="text-2xl md:text-3xl font-extrabold mt-5 md:mt-20 text-center md:text-left" style={{color: '#ffffff'}}/>
            </div>

            <div className="mb-3 flex items-center justify-between">
              <div className="w-full flex flex-col md:flex-row items-center justify-between">
                <span className="mr-4 flex items-center justify-center text-gray-300">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-2 text-primary" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10" />
                  </svg>
                  <Link to={`/${post.categories.nodes[0].slug}`} className="text-sm">{post.categories.nodes[0].name}</Link>
                </span>
                <span className="flex items-center justify-center text-gray-300">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-2 text-primary" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                  </svg>
                  <time className="text-sm" dateTime={moment(post.modified).format('LL')}>{moment(post.modified, "YYYYMMDD").fromNow()}</time>
                </span>
              </div>
            </div>

            {/* IMAGE FEATURED */}
            {post.featuredImage === null || post.featuredImage.node === null ? null : 
              <figure className="relative mb-10">
                <GatsbyImage image={image} alt={post.featuredImage.node.altText} className="bg-gray-900" />
                <figcaption dangerouslySetInnerHTML={{ __html: post.featuredImage.node.caption }} className="absolute z-30 bg-black bg-opacity-70 p-2 bottom-1 right-1 text-xs mt-2 px-5 text-gray-400" />
              </figure>
            }

            <article id="post_content" className="post_content gatsby-highlight text-gray-100 text-lg font-secondary border border-gray-900 bg-gray-900 bg-opacity-20 px-2 md:px-10" >
              {parse(post.content)}
            </article>

            <CallToActionFormation />

          </Container>
        </Layout>
      )
    }
    else {
      return (
        <main className="max-w-4xl flex-grow mx-auto flex flex-col justify-around">
          <div className="py-60">
            <h1 className="text-center text-6xl">Article non disponible</h1>
            <h2 className="text-center text-sm">Cet article n'existe plus ou a été déplacée.</h2>
            <div className="flex items-center justify-center">
              <Link
                to="/nos-actualites/"
                className="inline-block text-xs mt-5 bg-primary py-3 px-5 hover:bg-secondary text-white hover-ride-top-moy rounded-sm animates"
              >
                Retourner sur le blog
              </Link>
            </div>
          </div>
        </main>
      )
    }
  }
 
 export default BlogPost