import React, { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XIcon } from '@heroicons/react/outline'
import { Link } from 'gatsby'
import {
  AcademicCapIcon,
  CollectionIcon,
  CursorClickIcon,
  HomeIcon,
} from '@heroicons/react/solid'
import Picto from '../images/remoteMonkey/Pictogramme-Remote-Monkey.svg'
import Lettrage from '../images/remoteMonkey/Lettrage-Remote-Monkey.svg'

export default function MenuResponsive({open, setOpen}) {

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="fixed z-60 inset-0 overflow-hidden" onClose={setOpen}>
        <div className="absolute inset-0 overflow-hidden">
          <Dialog.Overlay className="absolute inset-0" />

          <div className="fixed inset-y-0 right-0 pl-10 max-w-full flex">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className="w-screen max-w-md">
                <div className="h-full flex flex-col bg-gray-900 shadow-xl overflow-y-scroll">
                  <div className="py-3 px-4 bg-gray-900 sm:px-6">
                    <div className="flex items-center justify-between">
                      <Link to="/" className="w-2/12 flex items-center justify-start animate">
                        <img src={Picto} alt="Remote Monkey" className="h-10 md:h-15 mr-2" />
                        <img src={Lettrage} alt="Remote Monkey" className="w-16 h-16 md:w-20 md:h-20" />
                      </Link>

                      <div className="ml-3 h-10 flex items-center">
                        <button
                          type="button"
                          className="bg-red-500 hover:bg-red-600 rounded-full text-white hover:text-white focus:outline-none focus:ring-2 focus:ring-red-600 animate p-1"
                          onClick={() => setOpen(false)}
                        >
                          <span className="sr-only">Fermer</span>
                          <XIcon className="h-5 w-5" aria-hidden="true" />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="relative py-6 px-4 sm:px-6">
                    <Link to="/" className="hover:text-secondary py-5 px-3 flex items-center border-b border-gray-700">
                      <HomeIcon className="h-5 w-5 mr-2 text-primary"/>
                      Accueil
                    </Link>
                    <Link to="/categories/" className="hover:text-secondary py-5 px-3 flex items-center border-b border-gray-700">
                      <CollectionIcon className="h-5 w-5 mr-2 text-primary"/>
                      Thématiques
                    </Link>
                    <a href="https://formations.arnaud-desportes.fr/" target="_blank" rel="noreferrer" className="hover:text-secondary py-5 px-3 flex items-center border-b border-gray-700">
                      <AcademicCapIcon className="h-5 w-5 mr-2 text-primary"/>
                      Formations
                    </a>
                    <a href="https://arnaud-desportes.fr/" target="_blank" rel="noreferrer" className="hover:text-secondary py-5 px-3 flex items-center border-b border-gray-700">
                      <CursorClickIcon className="h-5 w-5 mr-2 text-primary"/>
                      Développeur front-end freelance
                    </a>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

