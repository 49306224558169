import React from 'react'
import Container from '../component/Container'
import Layout from '../component/Layout'
import ListAllPostsBlog from '../component/listes/ListAllPosts'
import IconeVsCode from '../images/svg/visualstudio.svg'
import Header from '../component/Header'
import Seo from '../component/Seo'

export default function index() {
  return (
    <>
      <Seo 
        title="Remote Monkey - Blog Développeur Front-End react"
        description="Retrouves des articles de développement web, des articles de freelancing & de web design pour égayer ton parcours de codeur..." 
      />
      <Layout>
        <Header description="Des articles de développement web, freelancing & web design pour égayer ton parcours de codeur..." icone={IconeVsCode}>
          Développeur <span className="text-primary">Front-End React</span>
        </Header>

        {/* <Container className="text-center mt-10">
          <h3 className="text-2xl font-extrabold tracking-tights">Bienvenue sur Remote Monkey</h3>
          <h4 className="text-xl mt-1 text-primary">Un blog utile a la communauté des <b>développeurs Front-End React</b></h4>
        </Container> */}

        <Container>
          <ListAllPostsBlog className="my-10 mb-20 grid gap-2 md:grid-cols-2 lg:grid-cols-3 h-full" />
        </Container>
      </Layout>
    </>
  )
}
