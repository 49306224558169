import React from "react"
import { Helmet } from "react-helmet"
// import useSiteMetadata from "../hooks/useSiteMetadata"

const Seo = (props) => {
  // const { title, description } = useSiteMetadata()
  
  let { title, description } = props
  if (!title) { title = "ABMI Engineering - Ingénieriste Multi Spécialiste"}
  if (!description) { description = "Multi spécialiste en ingénierie nos experts sont mobilisés sur 8 différents secteurs d'activités en vous proposant une diversité d'offres et solutions pour répondre aux besoins de nos clients."}


  return ( 
    <Helmet>
      <html lang="fr" />
      <meta charSet="utf-8" />
      <meta name="description" content={description} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:type" content="website" />
      <title>{title}</title>
    </Helmet>
  )
}

export default Seo
