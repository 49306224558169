/**
 * Template Liste des Articles d'une categories
*/
import React from "react"
import { graphql, Link } from "gatsby"
// import { GatsbyImage, getImage } from "gatsby-plugin-image"
import 'moment/locale/fr'
// import moment from 'moment'
import Layout from '../component/Layout'
import Container from "../component/Container"
import Header from '../component/Header'
import Icone from '../images/svg/formations.svg'
import {GetDynamicImg} from '../utils/GetDynamicImg'
import Seo from "../component/Seo"

export const query = graphql`
  query Category {
    allWpCategory {
      edges {
        node {
          name 
          description
          slug
          id
          posts {
            nodes {
              title
              date
              id
              status
            }
          }
        }
      }
    } 
  }
`

const BlogCategory = ({ data }) => {

  const ArraylisteCategory = data.allWpCategory.edges
  
  return (
    <Layout>
      <Seo 
        title="Thématique du blog Remote Monkey"
        description="Deviens développeur web front-end et vie de ta passion pour le développement" 
      />

      <Header type="headerPostsList" description="Deviens développeur web front-end et vie de ta passion pour le développement" icone={Icone}>
        Thématiques du blog
      </Header>
      
      <Container>
        {ArraylisteCategory.length <= 0 ?
          <div className="flex flex-col items-center justify-center my-20 max-w-sm mx-auto text-center">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-16 w-16 bg-primary p-4 rounded-full text-white mb-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 3v4M3 5h4M6 17v4m-2-2h4m5-16l2.286 6.857L21 12l-5.714 2.143L13 21l-2.286-6.857L5 12l5.714-2.143L13 3z" />
            </svg>
            <span className="text-xl">Aucune catégorie de disponible pour le moment.</span>
          </div>
        :
          <div className="grid md:grid-cols-2 md:grid-rows-2 gap-2 mt-10">
            {ArraylisteCategory.map((cat, id) => (

              <Link to={`/categories/${cat.node.slug}/`} key={id} className={`border border-gray-800 hover:border hover:border-gray-700 animate group ${id === 0 ? 'row-span-1 md:row-span-2' : 'row-span-1'}`}>
                <div className={`relative w-full ${id === 0 ? 'h-64 md:h-full':'h-64'} overflow-hidden bg-gray-800 text-xs`}>
                  <img src={GetDynamicImg(cat.node.id)} alt={`Thématique du blog Remote Monkey : ${cat.node.name}`} className="group-hover:opacity-50 animate absolute z-0 h-full w-full object-cover object-center" />
                  <div className="absolute bottom-0 w-full z-10 bg-gray-900 bg-opacity-90">
                    <h2 className="mt-4 mb-3 text-lg font-black group-hover:text-indigo-500 animate px-4 h-full">{cat.node.name}</h2>
                    {/* <div className="text-sm mb-3 group-hover:text-indigo-300 animate px-4" dangerouslySetInnerHTML={{ __html: cat.node.description }} />  */}
                  </div>
                </div>
              </Link> 
             
            ))}
          </div>
        }
      </Container>
    </Layout>
  )
}

export default BlogCategory