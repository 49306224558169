import React from 'react'
import { Link } from 'gatsby'
import { HeartIcon } from '@heroicons/react/solid'

export default function Footer() {
  return (
    <footer className="border-t border-gray-800 pt-5 mt-16 mb-5 text-xs px-2 mx-auto text-center text-gray-500" style={{maxWidth: 680,}}>
      <span>© {new Date().getFullYear()}, developed with <HeartIcon className="inline-block text-red-500 h-4 w-4 -mt-1" /></span>
      <span className="block md:inline ml-0 md:ml-1">
        by
        {` `}
          <a className="hover:text-primary animate underline" href="https://arnaud-desportes.fr">Arnaud Desportes | Remote Monkey S.A.S.U</a>
        {` - `}
        <Link to="/credits" className="hover:text-primary animate">Crédits</Link>
      </span>
    </footer>
  )
}
