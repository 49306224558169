import React, {useState} from 'react'
import Container from './Container'
import Picto from '../images/remoteMonkey/Pictogramme-Remote-Monkey.svg'
import Lettrage from '../images/remoteMonkey/Lettrage-Remote-Monkey.svg'
import { Link } from 'gatsby'
import {
  AcademicCapIcon,
  CodeIcon,
  CollectionIcon,
  SparklesIcon,
} from '@heroicons/react/solid'
import MenuResponsive from './MenuResponsive'

export default function Menu() {

  const [open, setOpen] = useState(false)

  return (
    <>
    <MenuResponsive open={open} setOpen={setOpen} />
      <div className="w-full bg-gray-900 border-b border-gray-700 h-22 flex items-center justify-center">
        <Container className='flex items-center justify-between h-full'>
          <Link to="/" className="w-2/12 flex items-center justify-start animate">
            <img src={Picto} alt="Remote Monkey" className="h-10 md:h-15 mr-2" />
            <img src={Lettrage} alt="Remote Monkey" className="w-16 h-16 md:w-20 md:h-20" />
          </Link>
          <nav className="hidden md:block w-10/12 h-full">
            <div className="flex items-center justify-between text-xs h-full">
              <div className="w-8/12 flex items-center h-full">
                <Link to="/categories/" className="hover:text-secondary h-full px-3 flex items-center">
                  <CollectionIcon className="h-5 w-5 mr-2 text-primary"/>
                  Thématiques
                </Link>
                {/* <Link to="/categories/articles/" className="hover:text-secondary h-full px-3 flex items-center">
                  <CollectionIcon className="h-5 w-5 mr-2 text-primary"/>
                  Articles
                </Link> */}
                <Link to="/categories/ressources/" className="hover:text-secondary h-full px-3 flex items-center">
                  <SparklesIcon className="h-5 w-5 mr-2 text-primary"/>
                  Ressources
                </Link>
                <a href="https://formations.arnaud-desportes.fr/" target="_blank" rel="noreferrer" className="hover:text-secondary h-full px-3 flex items-center">
                  <AcademicCapIcon className="h-5 w-5 mr-2 text-primary"/>
                  Formations
                </a>
              </div>

              {/* <div className="flex items-center w-full">
                <button onClick={(e) => console.log('click search posts')} className="hover:text-secondary py-3 pl-3 mr-2"><SearchIcon className="h-10 w-10 text-primary hover:text-white hover:bg-primary rounded-md p-2 animate"/></button>
                <label htmlFor="search" className="sr-only">
                  Rechercher
                </label>
                <input
                  type="text"
                  name="search"
                  id="search"
                  className="bg-gray-700 py-2 pl-4 placeholder-gray-600 ring-1 ring-gray-800 focus:outline-none text-primary focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-sm"
                  placeholder="Recherche un article"
                />
              </div> */}

              <a href="https://arnaud-desportes.fr" target="_blank" rel="noreferrer" className="hover:text-secondary h-full px-3 flex items-center">
                <CodeIcon className="h-5 w-5 mr-2 text-primary"/>
                Développeur React Freelance
              </a>

              {/* <a href="https://arnaud-desportes.fr" target="_blank" rel="noreferrer" className="text-sm flex items-center justify-end text-primary underline hover:text-secondary w-4/12 animate"></a> */}

            </div>
          </nav>
          <button onClick={(e) => setOpen(!open)} className="md:hidden group bg-primary p-2 rounded-full hover:bg-secondary animate">
            <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="hamburger" className="w-4 h-4" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
              <path fill="currentColor" d="M464 256H48a48 48 0 0 0 0 96h416a48 48 0 0 0 0-96zm16 128H32a16 16 0 0 0-16 16v16a64 64 0 0 0 64 64h352a64 64 0 0 0 64-64v-16a16 16 0 0 0-16-16zM58.64 224h394.72c34.57 0 54.62-43.9 34.82-75.88C448 83.2 359.55 32.1 256 32c-103.54.1-192 51.2-232.18 116.11C4 180.09 24.07 224 58.64 224zM384 112a16 16 0 1 1-16 16 16 16 0 0 1 16-16zM256 80a16 16 0 1 1-16 16 16 16 0 0 1 16-16zm-128 32a16 16 0 1 1-16 16 16 16 0 0 1 16-16z"></path>
            </svg>
          </button>
        </Container>
      </div>
    </>
  )
}
